import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'
import SEO from '../components/seo'
import QuickFacts from '../components/quickFacts'
import GridImages from '../components/gridImages'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import {
   Tab,
   Tabs,
   Typography,
   Box,
   useTheme,
   makeStyles,
} from '@material-ui/core'
import sharedStyles from '../styles/global'
import ProjectParagraph from '../components/projectParagraph'
import ImageHeader from '../components/imageHeader'
import ProjectPapers from '../components/projectPapers'
import WithLocation from '../components/withLocation'
import Disclaimer from '../components/Disclaimer'
import {
   getQueryStringParams,
   determineInitialTab,
   determineActiveTab,
} from '../services/utilityService'
import ProjectPageImage from '../components/projectPageImage'
import Markdown from 'react-markdown/with-html'

function TabPanel(props) {
   const { children, value, index, ...other } = props
   return (
      <Typography
         component="div"
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-auto-tabpanel-${index}`}
         aria-labelledby={`scrollable-auto-tab-${index}`}
         {...other}
      >
         <Box>{children}</Box>
      </Typography>
   )
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.any.isRequired,
   value: PropTypes.any.isRequired,
}

function a11yProps(index) {
   return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
   }
}

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),

   tabHeader: {
      margin: theme.spacing(4, 0, 4, 0),
   },
   tabPanelContent: {
      '& p': {
         opacity: '0.9',
         margin: theme.spacing(0, 0, 3, 0),
      },
   },
   quickFactSection: {
      margin: theme.spacing(4, 0, 0, 0),
   },
   subHeader: {
      margin: theme.spacing(0, 2, 2, 3),
      [theme.breakpoints.down('xs')]: {
         margin: theme.spacing(0, 0, 1, 0),
      },
   },
   sideImageLayout: {
      margin: theme.spacing(2, 2),
      display: 'grid',
      gridTemplateColumns: '1fr 2fr',
      [theme.breakpoints.down('xs')]: {
         gridTemplateColumns: '1fr',
      },
   },
}))

const ProjectTemplate = ({ data, location }) => {
   const theme = useTheme()
   const project = data.strapiProject
   const css = useStyles(theme)

   const [tabParam, setTabParam] = useState('')
   const queryParams = getQueryStringParams(location.href)

   useEffect(() => {
      determineActiveTab(queryParams.tab, tabNames, setTabParam, setActiveTab)
   }, [tabParam])

   function handleTabChange(event, newTabIdx) {
      setActiveTab(newTabIdx)
      const newTabParamValue = tabNames[newTabIdx].toLowerCase()
      if (typeof window !== 'undefined') {
         const newURL = `${window.location.origin}${
            window.location.pathname
         }?tab=${encodeURIComponent(newTabParamValue)}`
         window.history.pushState(
            { path: newURL, tabIndex: newTabIdx },
            null,
            newURL
         )
      }
      setTabParam(newTabParamValue)
   }

   if (typeof window !== 'undefined') {
      window.addEventListener('popstate', function(data) {
         const state = data.state
         if (state && state.tabIndex >= 0) {
            setActiveTab(data.state.tabIndex)
         } else {
            setActiveTab(0)
         }
      })
   }

   const tabs = data.projectPagesJson ? data.projectPagesJson.tabs || [] : []

   const tabNames = data.projectPagesJson.tabs.map(tab => tab.tabTitle)

   const [activeTab, setActiveTab] = useState(() =>
      determineInitialTab(location.href, tabNames)
   )
   const images = data.allFile ? data.allFile.nodes || [] : []
   const quickFacts = project ? project.facts || [] : []
   const gridImages = project ? project.mediaImages || [] : []
   quickFacts.sort((a, b) => a.Position - b.Position)
   const title = project.ShowAcronym
      ? `${project.Name} (${project.Acronym})`
      : `${project.Name}`

   const metaDescription = data.projectPagesJson.metaDescription
   const metaKeywords = data.projectPagesJson.metaKeywords

   function determineActiveTab() {
      let specifiedTabIdx = -1
      let tabParamValue = ''
      if (queryParams.tab) {
         tabParamValue = decodeURIComponent(queryParams.tab)
         specifiedTabIdx = tabNames.findIndex(tabName => {
            return tabName.toLowerCase() === decodeURIComponent(queryParams.tab)
         })
      }
      if (specifiedTabIdx < 0) {
         specifiedTabIdx = 0 // default
         tabParamValue = tabNames[specifiedTabIdx].toLowerCase()
         setTabParam(tabParamValue)
      }
      setActiveTab(specifiedTabIdx)
   }

   return (
      <Layout>
         <SEO
            title={`${project.Name}`}
            description={`${metaDescription}`}
            keywords={`${metaKeywords}`}
         />
         <Helmet>
            <meta charSet="utf-8" />
         </Helmet>
         <ImageHeader
            title={title}
            color={project.Color}
            image={
               project.HeaderImage.localFile
            }
         ></ImageHeader>
         <div className={css.content}>
            {
            project.Acronym === 'SGSS' ||
            project.Acronym === 'NEN' ||
            project.Acronym === 'ESP' ||
            project.Acronym === 'SM' ||
            project.Acronym === 'SN' ? (
               <div>
                  <Disclaimer />
               </div>
            ) : (
               ''
            )}
            <Tabs
               value={activeTab}
               onChange={handleTabChange}
               indicatorColor="primary"
               textColor="primary"
               variant="scrollable"
               scrollButtons="auto"
               aria-label="scrollable auto tabs example"
            >
               {tabs.map((tab, idx) => {
                  let tabStyles = [css.tabControl, css.sansProSemiBold].join(
                     ' '
                  )
                  if (idx != activeTab)
                     tabStyles = [
                        css.tabControl,
                        css.sansProSemiBold,
                        css.nonActiveTab,
                     ].join(' ')
                  return (
                     <Tab
                        label={tab.tabTitle.toUpperCase()}
                        key={`${tab.tabTitle}-tab-${idx}`}
                        {...a11yProps(idx)}
                        className={tabStyles}
                     />
                  )
               })}
            </Tabs>
            {tabs.map((tab, idx) => (
               <TabPanel
                  value={activeTab}
                  index={idx}
                  key={`${tab.tabTitle}-tabpanel-${idx}`}
                  className={[css.tabPanelContent, css.openSans].join(' ')}
               >
                  <h2
                     className={[
                        css.sansProSemiBold,
                        css.pt40,
                        css.regular,
                        css.tabHeader,
                     ].join(' ')}
                  >
                     {tab.tabTitle}
                  </h2>
                  {tab.renderPublications ? (
                     <ProjectPapers
                        papers={data.papers.nodes}
                        collateral={data.collateral.nodes}
                     ></ProjectPapers>
                  ) : (
                     <>
                        {tab.paragraphs.map((paragraph, idx) => (
                           <ProjectParagraph
                              key={`${tab.tabTitle}-paragraph-${idx}`}
                              paragraph={paragraph}
                              images={images}
                           />
                        ))}

                        {tab.tabTitle.toUpperCase() === 'UPCOMING EVENTS'
                           ? data.events.nodes.map((event, ind) => (
                                <div className={css.sideImageLayout} key={ind}>
                                   <ProjectPageImage
                                      imageInfo={event.Image}
                                      fluidImage={
                                         event.Image.localFile.childImageSharp
                                            .fluid
                                      }
                                   />
                                   <div
                                      className={[
                                         css.subHeader,
                                         css.regular,
                                      ].join(' ')}
                                   >
                                      <h2>{event.Name}</h2>
                                      <Markdown
                                         source={event.EventTime.data.EventTime}
                                         escapeHtml={false}
                                      />
                                      <Markdown
                                         source={event.Description.data.Description}
                                         escapeHtml={false}
                                      />
                                   </div>
                                </div>
                             ))
                           : null}
                        {tab.tabTitle.toLowerCase() === 'overview' &&
                           gridImages.length > 0 ? (
                                 <GridImages
                                    gridImages={gridImages}
                                    color={project.Color}
                                 />
                           ) : null}
                        {tab.tabTitle.toLowerCase() === 'overview' &&
                        quickFacts.length > 0 ? (
                           <div className={css.quickFactSection}>
                              <h2
                                 className={[
                                    css.sansProSemiBold,
                                    css.pt40,
                                    css.uppercase,
                                    css.regular,
                                    css.tabHeader,
                                 ].join(' ')}
                              >
                                 Quick Facts
                              </h2>
                              <QuickFacts
                                 facts={quickFacts}
                                 color={project.Color}
                              />
                           </div>
                        ) : null}
                     </>
                  )}
               </TabPanel>
            ))}
         </div>
      </Layout>
   )
}

export default WithLocation(ProjectTemplate)

export const query = graphql`
   query ProjectTemplateQuery($acronym: String!) {
      strapiProject(Acronym: { eq: $acronym }) {
         Acronym
         ShowAcronym
         Name
         Color
         HeaderImage {
            localFile {
               childImageSharp {
                  fluid {
                     ...GatsbyImageSharpFluid
                  }
               }
            }
         }

         mediaImages:images {
            Title
            Add2Gallery
            Alt
            Caption
            Credit
            File {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 360, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              }
            }
          }

         facts {
            Alt
            Content
            Credit
            Position
            Image {
               name
               localFile {
                  publicURL
                  horizontal: childImageSharp {
                     fixed(cropFocus: CENTER, fit: COVER, height: 310) {
                        ...GatsbyImageSharpFixed
                     }
                  }
                  vertical: childImageSharp {
                     fixed(fit: COVER, cropFocus: CENTER, width: 310) {
                        ...GatsbyImageSharpFixed
                     }
                  }
               }
            }
         }
      }

      allFile(filter: { dir: { glob: "**/images/projects" } }) {
         nodes {
            childImageSharp {
               fluid(maxHeight: 960) {
                  ...GatsbyImageSharpFluid
                  originalName
               }
            }
         }
      }

      projectPagesJson(Acronym: { eq: $acronym }) {
         Acronym
         metaKeywords
         metaDescription
         tabs {
            tabTitle
            renderPublications
            paragraphs {
               rightSideImageLayout
               leftSideImageLayout
               personStatementLayout
               subheader
               textContent
               image {
                  name
                  caption
                  credit
               }
               callContactUS
               callRequestService
               textButton
               bulletList
            }
         }
      }

      papers: allStrapiPaper(
         filter: { projects: { elemMatch: { Acronym: { eq: $acronym } } } }
         sort: { order: DESC, fields: Publish }
      ) {
         nodes {
            Publish(formatString: "MMMM D, YYYY")
            Title
            Url
            projects {
               Acronym
            }
         }
      }

      events: allStrapiEvent(
         filter: { projects: { Acronym: { eq: "CIS" } } }
         sort: { order: ASC, fields: Position }
      ) {
         nodes {
            Name
            EventDate
            EventTime {
               data {
                  EventTime
               }
            }
            Description {
               data {
                  Description
               }
            }
            Image {
               localFile {
                  childImageSharp {
                     fluid {
                        ...GatsbyImageSharpFluid
                     }
                  }
               }
            }
            projects {
               Acronym
            }
         }
      }

      collateral: allStrapiCollateral(
         filter: { projects: { elemMatch: { Acronym: { eq: $acronym } } } }
         sort: { order: DESC, fields: Publish }
      ) {
         nodes {
            Publish(formatString: "MMMM D, YYYY")
            Title
            Blurb
            Alt
            projects {
               Acronym
            }
            File {
               localFile {
                  publicURL
               }
            }
            Thumbnail {
               id
               localFile {
                  publicURL
                  childImageSharp {
                     fluid(maxHeight: 960) {
                        ...GatsbyImageSharpFluid
                     }
                  }
               }
            }
         }
      }
   }
`
