import React from 'react'
import Layout from '../components/layout'
import sharedStyles from '../styles/global'
import { makeStyles,useTheme } from "@material-ui/core/styles"
import { useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    ...sharedStyles(theme),
    content: {
        padding: "10px",
        [theme.breakpoints.up('md')]: {
            padding: "30px",
        }
    },
    mainUL: {
        marginLeft: "20px",
        marginTop: "10px",
        marginBottom: "10px",
        [theme.breakpoints.up('md')]: {
            marginLeft: "40px",
        }
    },
    subUL: {
        listStyleType: "circle",
        marginLeft: "20px",
        [theme.breakpoints.up('md')]: {
            marginLeft: "40px",
        }
    },
    paragraphSpacing:{
        '& p':{
            marginBottom:'0.75rem'
        }
    }
}))



const Policy = props => {
    const css = useStyles()
    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
    const headerStyles = [
        css.sansProSemiBold,
        css.serviceHeader,
        isLargeScreen ? css.pt40 : css.pt30,
     ].join(' ')
     const headerSubStyles = [
        css.sansProSemiBold,
        css.serviceHeader,
        isLargeScreen ? css.pt30 : css.pt20,
     ].join(' ')
    return (

        <Layout>

            <div id="content" className={[css.content, css.paragraphSpacing].join(' ')}>

                <div class="static-landing-page">
                <p className={headerStyles}><br/><h1>NASA Web Privacy Policy and Important Notices</h1><br/></p>
                    <p>Thank you for visiting NASA and reviewing our policy notices. We have integrated these statements into a single posting for ease of use. The following links will help you navigate to a specific section:</p>

                    <ul className={css.mainUL}>
                        <li><a href="#privacy">Privacy Policy</a>
                            <ul className={css.subUL}><li><a target="_blank" rel="noopener noreferrer" href="http://www.fpc.gov/">Federal Privacy Council</a></li>
                            </ul>
                        </li>
                        <li><a href="#security">Security Notice </a></li>
                        <li><a href="#508">Accessibility Statement </a></li>
                        <li><a href="#links">Linking Policy and Disclaimer of Endorsement </a></li>
                        <li><a href="#otherrequiredlinks">Other Links</a>
                            <ul className={css.subUL}><li><a target="_blank" rel="noopener noreferrer" href="http://www.nasa.gov/news/budget/index.html">NASA Strategic Plans and Performance Plans</a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="http://www.usa.gov/">USA.gov</a></li>
                            </ul></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="/content/privacy-on-nasa-social-media-channels">Privacy on NASA Social Media Channels</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="http://www.nasa.gov/privacy/PIA.html">Privacy Impact Assessment</a></li>
                        <li><a href="#officials">NASA Officials for Privacy Related Matters</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="http://www.nasa.gov/privacy/index.html">NASA Privacy Program</a></li>
                    </ul><a name="privacy" id="privacy"></a>

                    <br/>

                     <p className={headerSubStyles}>Privacy Policy</p>

                    <p>This notice provides NASA's policy regarding the nature, purpose, use and sharing of any information collected via this Web site. The information you provide on a NASA Web site will be used only for its intended purpose. We will protect your information consistent with the principles of the Privacy Act, the e-Government act of 2002, the Federal Records Act, and as applicable, the Freedom of Information Act.</p>

                    <p>Submitting information is strictly voluntary. By doing so, you are giving NASA your permission to use the information for the intended purpose. If you do not want to give NASA permission to use your information, simply do not provide it. However, not providing certain information may result in NASA's inability to provide you with the information or services you desire.</p>

                    <p>There are several types of information we collect. These include:</p>

                    <ol className={css.mainUL}><li><a href="#metrics">Automatically Collected Information </a></li>
                        <li><a href="#cookies">Information Collected for Tracking and Customization </a> (Cookies)</li>
                        <li><a href="#personal">Personal Information </a></li>
                        <li><a href="#coppa">Information from Children </a></li>
                        <li><a href="#pasor">Information maintained within Privacy Act Systems of Records</a></li>
                    </ol><p>NASA will only share your information with another government agency if it relates to that agency, or as otherwise required by law. We may share information with private organizations as part of a service that provides NASA.gov users with increased capabilities or functionality on the site. NASA never creates individual profiles.</p>
                    <a name="metrics" id="metrics"></a>

                    <br/>

                    <p className={headerSubStyles}>Automatically Collected Information</p>

                    <p>This website uses Google Analytics Premium. Please refer to the following policies on Google's website for more information:</p>

                    <ul className={css.mainUL}><li><a target="_blank" rel="noopener noreferrer" href="http://www.google.com/intl/en/policies/privacy/">Google's main privacy policy</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://developers.google.com/analytics/devguides/collection/gajs/cookie-usage">Cookies &amp; Google Analytics on Websites</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://tools.google.com/dlpage/gaoptout?hl=en">Opt out of Google Analytics Cookies</a></li>
                    </ul><p>We collect and temporarily store certain technical information about your visit for use in site management and security purposes. This information includes:</p>

                    <ol className={css.mainUL}><li>The Internet domain from which you access our Web site (for example, "xcompany.com" if you use a private Internet access account, or "yourschool.edu" if you connect from an educational domain);</li>
                        <li>The IP address (a unique number for each computer connected to the Internet) from which you access our Web site;</li>
                        <li>The type of browser (e.g., Chrome, Firefox, Internet Explorer) used to access our site;</li>
                        <li>The operating system (Windows, Mac) used to access our site;</li>
                        <li>The date and time you access our site;</li>
                        <li>The URLs of the pages you visit;</li>
                        <li>Your username, if it was used to log in to the Web site; and</li>
                        <li>If you visited this NASA Web site from another Web site, the URL of the forwarding site.</li>
                        <li>Anonymous summary demographic information about our visitors such as gender, age range, and areas of interest for adults over the age of 18. We do this by using Google Demographic and Interests reports. When you visit a website that has partnered with the Google Display Network, Google stores a number in your browser using a persistent cookie to remember your visits. This number uniquely identifies a web browser, not a specific person. Browsers may be associated with a demographic category, such as gender or age range, based on the sites that were visited. This demographic information is used to help us better understand our visitors' interests and needs to more effectively develop content to serve you.</li>
                    </ol><p>This information is generally used to help us make our site more useful for you. With this data we learn about the number of visitors to our site and the types of technology our visitors use.&nbsp; Although the primary purpose of automatically collecting this kind of information is not to track individuals who visit this site, in certain circumstances and consistent with Federal law, NASA may take additional steps to identify you using this information and may share this information, including your identity, with other agencies.&nbsp;</p>

                    <p>Except for authorized law enforcement investigations, no attempts are made to identify individual users or their usage habits. Raw data logs are retained temporarily as required for security and site management purposes only.</p>
                    <a name="cookies" id="cookies"></a>

                    <br/>

                    <p className={headerSubStyles}>Information Collected for Tracking and Customization (Cookies)</p>

                    <p>A cookie is a small file that a Web site transfers to your computer to allow it to remember specific information about your session while you are connected. Your computer will only share the information in the cookie with the Web site that provided it, and no other Web site can request it. There are two types of cookies, session and persistent. Session cookies last only as long as your Web browser is open. Once you close your browser, the cookie disappears. Persistent cookies store information on your computer for longer periods of time.</p>

                    <p>NASA Web sites may use session cookies for technical purposes such as to enable better navigation through the site, or to allow you to customize your preferences for interacting with the site. A few NASA Web sites may also make use of persistent cookies to remember you between visits so, for example, you can save your customized preference settings for future visits. Each NASA site using persistent cookies identifies itself as doing so. If you do not wish to have session or persistent cookies stored on your machine, you can turn them off in your browser. However, this may affect the functioning of some NASA Web sites.</p>

                    <br/>
                    <p className={headerSubStyles}>Providing Information to Third Parties</p>

                    <p>NASA may share information with private organizations as part of a service that provides NASA users with increased capabilities or functionality on the site. On <a target="_blank" rel="noopener noreferrer" href="http://www.nasa.gov">www.nasa.gov</a>, the "Share" function at the top of each page is provided by a third party, AddThis. This firm collects information on visitors who use this feature to share NASA content on their Facebook pages, Twitter feeds or other social media or social networking sites. As noted in their <a target="_blank" rel="noopener noreferrer" href="http://www.addthis.com/privacy">privacy policy</a> AddThis uses this information for its own business purposes, including marketing the information to other parties.</p>

                    <p>AddThis does not collect information from users who do not use the feature.</p>
                    <a name="personal" id="personal"></a>

                    <br/>

                    <p className={headerSubStyles}>Personal Information</p>

                    <p>If you choose to provide us with personal information, through such methods as completing a form or sending us an email, we will use that information to respond to your message and to help us get you the information or services you have requested.</p>

                    <p>Remember that email isn't necessarily secure. You should never send sensitive or personal information like your Social Security number in an email. Use postal mail or secure Web sites instead.</p>

                    <p>Some of our Web sites ask visitors who request specific information to fill out a registration form. For example, vendors looking for marketing opportunities by visiting our NASA Acquisition Internet Service site may be asked to register to obtain email notices of business opportunities. Other information collected at Web sites through questionnaires, feedback forms, or other means, enables us to determine visitors' interests, with the goal of providing better service to our customers.</p>
                    <a name="coppa" id="coppa"></a>

                    
                    <br/>
                    <p className={headerSubStyles}>Interaction with Children</p>

                    <p>The <a target="_blank" rel="noopener noreferrer" href="http://www.ecfr.gov/cgi-bin/text-idx?SID=4939e77c77a1a1a08c1cbf905fc4b409&amp;node=16%3A1.0.1.3.36&amp;rgn=div5">Children's Online Privacy Protection Act (COPPA)</a> governs information gathering online from or about children under the age of 13. Verifiable consent from a child's parent or guardian is required before collecting, using, or disclosing personal information from a child under the age of 13.</p>

                    <p>We collect no information about you or your child, other than that detailed in the previous section, when you visit our web site unless you choose to provide information to us. When a NASA Web site needs to collect information about a child under 13 years old, COPPA required information and instructions will be provided by the specific Web page that collects information about the child. The Web page will specify exactly what the information will be used for, who will see it, and how long it will be kept.</p>

                    <p>There are several exceptions that permit collection of a child's email address without receiving parental consent in advance:</p>

                    <ul className={css.mainUL}><li>To provide the parents with notice and to seek consent for communications with the child. Note: this may require collection of the parent's email address as well.</li>
                        <li>To respond to a one time request from a child.</li>
                        <li>To respond more than once to a child's request; i.e., subscription to a newsletter. However, parental consent is required prior to the second communication.</li>
                        <li>To protect the safety of a child who is participating on the site; i.e., in a chat room.</li>
                        <li>To protect the site or to respond to law enforcement; i.e., in the case of a Web site compromise.</li>
                    </ul><p>Personal information about children under 13 years of age may be needed to respond to his/her communication to us, such as to receive a poster or to acquire information for a school project. Personal information about your child will be destroyed immediately upon completion of its intended purpose. On rare occasions, it may be determined that a communication from a child under 13 years old should be maintained for historical purposes. Should such an occasion occur, NASA will obtain the necessary consent from the child's parent.</p>

                    <p>Finally, we provide many on-line tools and services in support of NASA's mission. A child under 13 years old may inadvertently provide personal information to one of these services. If this should happen, the information about the child will be deleted immediately upon discovery.</p>
                    <a name="pasor" id="pasor"></a>

                    <br/>

                    <p className={headerSubStyles}>Information maintained within Privacy Act Systems of Records</p>

                    <p>NASA collects and maintains some records on individuals that are protected under the Privacy Act of 1974 and from which the records are retrieved by individual’s name/personal identifier.&nbsp; Such information is included in <a target="_blank" rel="noopener noreferrer" href="http://www.nasa.gov/privacy/nasa_sorn_index.html">NASA Privacy Act Systems of Records</a> which are published through notices in the Federal Register.&nbsp; Individuals may request records held by NASA on them in NASA’s Systems of Records by following the instructions in <a target="_blank" rel="noopener noreferrer" href="http://www.ecfr.gov/cgi-bin/text-idx?c=ecfr;sid=169d0562b90bf7fbbc659a6308e971ea;rgn=div5;view=text;node=14%3A5.0.1.1.13;idno=14;cc=ecfr#se14.5.1212_1201">14 CFR 1212.201</a>.”</p>
                    <a name="security" id="security"></a>

                    <br/>

                     <p className={headerSubStyles}>Web Site Security Notice</p>

                    <p>For site security purposes and to ensure that this Web service remains available to all users, this Government computer system employs software programs that monitor network traffic to identify unauthorized attempts to upload or change information,. Anyone using this system expressly consents to such monitoring and is advised that if such monitoring reveals evidence of possible abuse or criminal activity, such evidence may be provided to appropriate law enforcement officials.</p>

                    <p>Unauthorized attempts to upload or change information on NASA servers are strictly prohibited and may be punishable by law, including under the Computer Fraud and Abuse Act of 1986 and the National Information Infrastructure Protection Act of 1996.</p>

                    <p>Additionally, NASA information systems may be protected by EINSTEIN cybersecurity capabilities under the operational control of the U.S. Department of Homeland Security United States Computer Emergency Readiness Team (US-CERT). Electronic communications with NASA may be scanned by government-owned or contractor equipment to look for network traffic indicating known or suspected malicious cyber activity, including malicious content or communications.</p>

                    <p>Electronic communications within NASA will be collected or retained by US-CERT only if they are associated with known or suspected cyber threats. US-CERT will use the information collected through EINSTEIN to analyze the known or suspected cyber threat and help NASA and other agencies respond and better protect their computers and networks.</p>

                    <p>For additional information about EINSTEIN capabilities, please see the EINSTEIN program-related Privacy Impact Assessments available on the&nbsp;<a target="_blank" rel="noopener noreferrer" href="https://www.dhs.gov/topic/privacy">U.S. Department of Homeland Security cybersecurity privacy website</a>&nbsp;along with other information about the federal government’s cybersecurity activities.</p>

                    <div></div>
                    <a name="508" id="508"></a>

                    <br/>

                     <p className={headerSubStyles}>Accessibility Statement</p>

                    <p>The National Aeronautics and Space Administration is committed to making all of our Agency programs and services, including&nbsp;electronic and information technology, accessible to everyone. To ensure that we meet or exceed the requirements of&nbsp;<a target="_blank" rel="noopener noreferrer" href="http://www.dol.gov/oasam/regs/statutes/sec504.htm">Section 504 of the Rehabilitation Act of 1973</a>&nbsp;and&nbsp;the&nbsp;<a target="_blank" rel="noopener noreferrer" href="http://www.section508.gov/section508-laws">1998 Amendment to Section 508 of the Rehabilitation Act of 1973</a>, we continually review our programs and services, including electronic and information technology, in order to remove accessibility problems for people with disabilities.</p>

                    <p>If you have a problem accessing any NASA program&nbsp;or if you have questions about our accessibility policy, please contact our hotline at 866-654-1440 or by email at&nbsp;<a target="_blank" rel="noopener noreferrer" href="mailto:AssistedProgramComplaint@nasa.gov">AssistedProgramComplaint@nasa.gov</a>.</p>

                    <p>NASA's commitment to accessibility includes making its web content accessible. Here's more on <a target="_blank" rel="noopener noreferrer" href="http://www.nasa.gov/content/website-accessibility/index.html">website accessibility</a>.</p>
                    <a name="social" id="social"></a>

                    <br/>
<br/>
                     <p className={headerSubStyles}>Linking Policy and Disclaimer of Endorsement</p>

                    <p>NASA links to many Web sites created and maintained by other public and/or private organizations. NASA provides links to these sites as a service to our users. The presence of a link is not a NASA endorsement of the site.</p>

                    <p>When users follow a link to an outside Web site, they are leaving NASA and are subject to the privacy and security policies of the owners/sponsors of the outside Web site(s). NASA is not responsible for the information collection practices of non-NASA sites.</p>
                    <a name="otherrequiredlinks" id="otherrequiredlinks"></a>

                    <br/>

                    <p className={headerSubStyles}><a name="pias" id="links0"></a>Other Links</p>
                  
                    <p><b>NASA Privacy Impact Assessments (PIAs)</b></p>

                    <p>For a list of Approved Privacy Impact Assessments (PIAs) <a target="_blank" rel="noopener noreferrer" href="http://www.nasa.gov/privacy/PIA.html">Click here</a>.<br />
                        14 CFR 1212 - NASA Privacy Act Regulations <a target="_blank" rel="noopener noreferrer" href="https://www.gpo.gov/fdsys/pkg/CFR-1999-title14-vol5/xml/CFR-1999-title14-vol5-part1212-subpart1212-2.xml">Click here.</a><br />
                        NASA Systems of Records Notices (SORNs) <a target="_blank" rel="noopener noreferrer" href="https://www.nasa.gov/content/nasa-privacy-act-system-of-records-notices-sorns">Click here</a>.</p>
                        <br/>
                    <p><b>NASA Officials for Privacy Related Matters</b><a name="officials" id="links0"></a></p>

                    <p><b>NASA Senior Agency Official for Privacy</b><strong> (SAOP)</strong><br />
                        Renee P. Wynn<br />
                        Chief Information Officer</p>
                        <br/>
                    <p><b>NASA Chief Privacy Officer (CPO)&nbsp;</b><br /><a target="_blank" rel="noopener noreferrer" href="mailto:stayce.d.hoult@nasa.gov">Stacye Hoult</a>&nbsp;(acting)<br />
                        NASA Office of the Chief Information Officer<br />
                        Huntsville, AL<br />
                        256-544-7705</p>
                        <br/>
                    <p><b>NASA Agency Privacy Act Officer</b><br /><a target="_blank" rel="noopener noreferrer" href="mailto:patti.stockman@nasa.gov">Patti F. Stockman</a><br />
                        NASA Office of the Chief Information Officer<br />
                        NASA Headquarters<br />
                        Washington, DC 20546-0001<br />
                        202-358-4787</p>
                </div>

            </div>
        </Layout>
    )
}

export default Policy