import React from 'react'
import Markdown from 'react-markdown/with-html'
import ProjectPageImage from '../components/projectPageImage'
import { useTheme, makeStyles } from '@material-ui/core'
import sharedStyles from '../styles/global'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   subHeader: {
      margin: theme.spacing(0, 0, 2, 0),
      [theme.breakpoints.down('xs')]: {
         margin: theme.spacing(0, 0, 1, 0),
      },
   },
   rightSideImageLayout: {
      margin: theme.spacing(0, 0, 0, 0),
      padding: theme.spacing(0, 2, 0, 2),
      display: 'grid',
      gridTemplateColumns: '1fr 2fr',
      [theme.breakpoints.down('xs')]: {
         gridTemplateColumns: '1fr',
         margin: theme.spacing(0, 1),
      },
   },

   personStatementLayout: {
      margin: theme.spacing(6, 0, 0, 0),
      padding: theme.spacing(2, 2, 0, 2),
      backgroundColor: 'rgba(255, 182, 18, 0.1)',
      display: 'grid',
      gridTemplateColumns: '1fr 6fr',
      [theme.breakpoints.down('xs')]: {
         gridTemplateColumns: '1fr',
         margin: theme.spacing(0, 1),
      },
   },

   textSide: {
      padding: theme.spacing(0, 4, 2, 3),
      [theme.breakpoints.down('xs')]: {
         padding: theme.spacing(0),
      },
   },
   spanTwoCols: {
      gridColumn: '1 / span 2',
      padding: theme.spacing(0),
   },
   bulletStyling: {
      '& ul': {
         opacity: '0.9',
      },
   },
}))

const ProjectParagraphImageRight = props => {
   const theme = useTheme()
   const css = useStyles(theme)

   const {
      subheader,
      paragraphText,
      imageInfo,
      fluidImage,
      rightSideImageLayout,
      personStatementLayout,
   } = props

   const textSideClasses = [
      css.textSide,
      css.bulletStyling,
      fluidImage ? '' : css.spanTwoCols,
   ].join(' ')

   const divStyle = [
      rightSideImageLayout ? css.rightSideImageLayout : '',
      personStatementLayout ? css.personStatementLayout : '',
   ].join(' ')

   return (
      <div className={divStyle}>
         {fluidImage ? (
            <ProjectPageImage imageInfo={imageInfo} fluidImage={fluidImage} />
         ) : null}

         <div className={textSideClasses}>
            {subheader && personStatementLayout ? (
               <h3 className={[css.subHeader, css.sansProSemiBold].join(' ')}>
                  {subheader}
               </h3>
            ) : null}

            {subheader && rightSideImageLayout ? (
               <h2 className={[css.subHeader, css.regular].join(' ')}>
                  {subheader}
               </h2>
            ) : null}

            <Markdown source={paragraphText} escapeHtml={false} />
         </div>
      </div>
   )
}

export default ProjectParagraphImageRight
