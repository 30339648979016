import React from 'react'
import Markdown from 'react-markdown/with-html'
import PartnershipPageImage from '../components/partnershipPageImage'
import PartnershipParagraphImageLeft from '../components/PartnershipParagraphImageLeft'
import PartnershipParagraphImageRight from '../components/PartnershipParagraphImageRight'
import CallContactUS from './callContactUSComp'
import CallRequestService from './callRequestServiceComp'
import { useTheme, makeStyles } from '@material-ui/core'
import sharedStyles from '../styles/global'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   subHeader: {
      margin: theme.spacing(0, 0, 1, 0),
   },
   bulletStyling: {
      '& ul': {
         opacity: '0.9',
      },
      '& ol': {
         opacity: '0.9',
         marginBottom: '24px',
      },
   },
}))

const PartnershipParagraph = props => {
   const theme = useTheme()
   const css = useStyles(theme)

   const {
      textContent,
      bulletList,
      image,
      subheader,
      leftSideImageLayout,
      rightSideImageLayout,
      personStatementLayout,
      callContactUS,
      callRequestService,
      textButton,
   } = props.paragraph

   let paragraphText
   if (textContent && textContent.length > 0) {
      paragraphText = textContent.join(' ')
   } else if (bulletList && bulletList.length > 0) {
      paragraphText = bulletList.join('\r\n')
   }

   let fluidImageNode
   let fluidImage = null
   if (image) {
      fluidImageNode = props.images.find(imageNode => {
         if (imageNode.childImageSharp != null) {
            return (
               imageNode.childImageSharp.fluid.originalName.toLowerCase() ===
               image.name.toLowerCase()
            )
         }
      })
   }
   if (fluidImageNode) {
      fluidImage = fluidImageNode.childImageSharp.fluid
   }

   return (
      <>
         {leftSideImageLayout ? (
            <PartnershipParagraphImageRight
               subheader={subheader}
               paragraphText={paragraphText}
               imageInfo={image}
               fluidImage={fluidImage}
               leftSideImageLayout={leftSideImageLayout}
            />
         ) : rightSideImageLayout || personStatementLayout ? (
            <PartnershipParagraphImageLeft
               subheader={subheader}
               paragraphText={paragraphText}
               imageInfo={image}
               fluidImage={fluidImage}
               rightSideImageLayout={rightSideImageLayout}
               personStatementLayout={personStatementLayout}
            />
         ) : callContactUS ? (
            <CallContactUS
               subheader={subheader}
               paragraphText={paragraphText}
               imageInfo={image}
               fluidImage={fluidImage}
               textButton={textButton}
               callContactUS={callContactUS}
            />
         ) : callRequestService ? (
            <CallRequestService
               subheader={subheader}
               paragraphText={paragraphText}
               imageInfo={image}
               fluidImage={fluidImage}
               textButton={textButton}
               callRequestService={callRequestService}
            />
         ) : (
            <>
               {subheader ? (
                  <h2 className={[css.subHeader, css.regular].join(' ')}>
                     {subheader}
                  </h2>
               ) : null}
               <span className={css.bulletStyling}>
                  <Markdown source={paragraphText} escapeHtml={false} />
               </span>
               {fluidImage ? (
                  <PartnershipPageImage
                     imageInfo={image}
                     fluidImage={fluidImage}
                  />
               ) : null}
            </>
         )}
      </>
   )
}

export default PartnershipParagraph
