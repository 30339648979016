import React from 'react'
import Img from 'gatsby-image'
import { useTheme, makeStyles } from '@material-ui/core'
import sharedStyles from '../styles/global'
import Caption from '../components/caption'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   imageContainer: {
      margin: theme.spacing(0, 0, 3, 0),
   },
}))

const PartnershipPageImage = props => {
   const theme = useTheme()
   const css = useStyles(theme)

   const { imageInfo, fluidImage } = props

   return (
      <div className={css.imageContainer}>
         <Img fluid={fluidImage} alt={imageInfo.name} objectFit="contain" />
         <Caption text={imageInfo.caption} credit={imageInfo.credit}></Caption>
      </div>
   )
}

export default PartnershipPageImage
