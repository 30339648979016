exports.filterArticles = articles => {
   if (articles) {
      const environment = process.env.ENVIRONMENT
      let filteredArticles = articles.filter(article => !article.IsDataExample)

      //-- filter based on environment and publish date
      if (environment === 'pre-production' || environment === 'production') {
         const today = new Date()
         filteredArticles = filteredArticles.filter(article => {
            const publish = new Date(article.Publish)
            const includeArticle = publish <= today
            return includeArticle
         })
      }

      return filteredArticles
   }
   return []
}

exports.findImage = (imageRef, allImages, fromRelatedArticlesComponent) => {
   // Todo - Come back and double check we need this if statement after adding the new ImageOverlayArticle component. Delete if else statement if not needed.
   if (fromRelatedArticlesComponent) {
      let image = null
      if (imageRef) {
         const imageId = imageRef.id

         image = allImages.find(img => {
            return img.id === imageId
         })
      }
      return image
   } else {
      let image = null
      if (imageRef) {
         const imageId = imageRef.id
         image = allImages.find(img => {
            return img.File.id === imageId
         })
      }
      return image
   }
}
