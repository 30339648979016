exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-health-js": () => import("./../../../src/pages/health.js" /* webpackChunkName: "component---src-pages-health-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-network-int-services-js": () => import("./../../../src/pages/networkIntServices.js" /* webpackChunkName: "component---src-pages-network-int-services-js" */),
  "component---src-pages-news-media-js": () => import("./../../../src/pages/news-media.js" /* webpackChunkName: "component---src-pages-news-media-js" */),
  "component---src-pages-other-services-js": () => import("./../../../src/pages/otherServices.js" /* webpackChunkName: "component---src-pages-other-services-js" */),
  "component---src-pages-participate-js": () => import("./../../../src/pages/participate.js" /* webpackChunkName: "component---src-pages-participate-js" */),
  "component---src-pages-participate-sip-js": () => import("./../../../src/pages/participateSIP.js" /* webpackChunkName: "component---src-pages-participate-sip-js" */),
  "component---src-pages-partnerships-js": () => import("./../../../src/pages/partnerships.js" /* webpackChunkName: "component---src-pages-partnerships-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-publication-js": () => import("./../../../src/pages/publication.js" /* webpackChunkName: "component---src-pages-publication-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-partnership-js": () => import("./../../../src/templates/partnership.js" /* webpackChunkName: "component---src-templates-partnership-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

