import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { AccessTime } from '@material-ui/icons'
import sharedStyles from '../styles/global'
import Collateral from './collateral'
import _ from 'lodash'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   paper: {
      marginTop: '.5rem',
      marginBottom: '.5rem',
   },
   sectionSpacing: {
      marginBottom: '2rem',
   },
   subHeadingSpacing: {
      marginBottom: '1rem',
   },
   dateSpacing: {
      marginTop: '.25rem',
   },
}))

export const PartnershipPapers = props => {
   const theme = useTheme()
   const css = useStyles(theme)

   const collateral = props.collateral
   const papers = props.papers

   return (
      <>
         {papers && papers.length > 0 && (
            <div className={css.sectionSpacing}>
               <h3 className={[css.subHeadingSpacing].join(' ')}>
               Papers, Articles, &amp; Documents
               </h3>
               {papers.map((paper, index) => {
                  return (
                     <div
                        className={[css.paper, css.opacity90].join(' ')}
                        key={`Paper-${index}`}
                     >
                        <div>
                           <a href={paper.url} target="_blank">
                              {paper.title}
                           </a>
                        </div>
                        <div
                           className={[
                              css.openSans,
                              css.pt18,
                              css.italic,
                              css.dateSpacing,
                           ].join(' ')}
                        >
                           <AccessTime style={{ fontSize: '12px' }} />
                           &nbsp;
                           {paper.publish}
                        </div>
                     </div>
                  )
               })}
            </div>
         )}
         {collateral && collateral.length > 0 && (
            <div className={css.sectionSpacing}>
               <h3 className={css.subHeadingSpacing}>Downloadables</h3>
               <Collateral items={collateral}></Collateral>
            </div>
         )}
      </>
   )
}

export default PartnershipPapers
