import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
   Checkbox,
   FormControl,
   Input,
   InputLabel,
   MenuItem,
   Select,
   TextField,
   Typography,
   useMediaQuery,
} from '@material-ui/core'
import Layout from '../components/layout'
import ImageHeader from '../components/imageHeader'
import MediaTabs from '../components/mediaTabs'
import sharedStyles from '../styles/global'
import Collateral from '../components/collateral'
import _, { map } from 'underscore'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   formControl: {
      marginBottom: '20px',
      margin: theme.spacing(1),
      minWidth: '100px',
      maxWidth: '500px', //this is the with expanding when selecting amount of selected projects
      [theme.breakpoints.down('sm')]: {
         minWidth: '100px',
         maxWidth: '150px',
      },
   },
   titleSpacing: {
      paddingTop: '0.6rem',
      paddingBottom: '0.2rem',
      lineHeight: 1.2,
      color: '#3e63d3',
   },
   buttonContainer: {
      width: '100%',
      textAlign: 'center',
   },
}))

const PublicationPage = ({ data }) => {
   const theme = useTheme()
   const css = useStyles(theme)

   const [visible, setVisible] = useState(1)
   const allCollateral = data.collaterals.nodes.map(collateral => {
      collateral.Thumbnail = collateral.Thumbnail
         ? collateral.Thumbnail
         : collateral.missingImage
      return collateral
   })
   let columns = setColumns()
   function setColumns() {
      let columns = 4
      if (useMediaQuery(theme.breakpoints.down('sm'))) {
         columns = 1
      }
      if (useMediaQuery(theme.breakpoints.up('sm'))) {
         columns = 2
      }
      if (useMediaQuery(theme.breakpoints.up('md'))) {
         columns = 3
      }
      if (useMediaQuery(theme.breakpoints.up('lg'))) {
         columns = 4
      }
      return columns
   }

   const [visibleCollaterals, setVisibleCollaterals] = useState(
      allCollateral.slice(0, 2 * columns * visible)
   )
   const allProjects = data.projects.nodes
   const [searchCriteria, setSearchCriteria] = useState({
      projects: [],
      searchText: '',
   })
   const projectNames = searchCriteria.projects || []

   function showMore(event) {
      setVisible(visible + 1)
      const numVisible = columns * 2 * (visible + 1)
      setVisibleCollaterals(allCollateral.slice(0, numVisible))
   }

   function determineSeeMoreVisibility() {
      const numVisible = visibleCollaterals.length
      const total = allCollateral.length
      if (numVisible === total) {
         return false
      }
      return true
   }

   function handleProjectSelection(event) {
      const acronyms = event.target.value
      searchCriteria.projects = acronyms
      setSearchCriteria(searchCriteria)
      filterCollaterals(searchCriteria)
   }

   function handleSearchUpdate(event) {
      searchCriteria.searchText = event.target.value
      setSearchCriteria(searchCriteria)
      filterCollaterals(searchCriteria)
   }

   function filterCollaterals(criteria) {
      let visible = allCollateral
      if (criteria && criteria.projects && criteria.projects.length > 0) {
         visible = visible.filter(collateral => {
            if (collateral && collateral.projects) {
               const intersection = _.intersection(
                  criteria.projects,
                  collateral.projects.map(x => x.Acronym)
               )
               if (intersection.length > 0) return true
            } else {
               return false
            }
         })
      }

      if (criteria.searchText) {
         const searchText = criteria.searchText
         visible = visible.filter(x => {
            if (isSubStringFound(x.Title, searchText)) return true
            if (isSubStringFound(x.Caption, searchText)) return true
            return false
         })
      }
      setVisibleCollaterals(visible)
   }

   function isSubStringFound(text, searchText) {
      if (text) {
         const loweredText = text.toLowerCase()
         if (loweredText.indexOf(searchText.toLowerCase()) > -1) return true
      }
      return false
   }

   return (
      <Layout>
         <ImageHeader
            title={`News & Multimedia`}
            image={data.headerImage}
         ></ImageHeader>
         <div className={css.content}>
            <MediaTabs selected={3}></MediaTabs>
            <div>
               <div className={css.searchBox}>
                  <span>
                     <FormControl className={css.formControl}>
                        <InputLabel htmlFor="select-multiple-checkbox">
                           Projects
                        </InputLabel>
                        <Select
                           multiple
                           value={projectNames}
                           onChange={handleProjectSelection}
                           input={<Input id="select-multiple-checkbox" />}
                           renderValue={selected => selected.join(', ')}
                        >
                           {allProjects.map(proj => (
                              <MenuItem key={proj.Acronym} value={proj.Acronym}>
                                 <Checkbox
                                    checked={
                                       projectNames.indexOf(proj.Acronym) > -1
                                    }
                                 />

                                 <Typography variant="inherit" noWrap>
                                    {proj.Name}
                                 </Typography>
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                  </span>
                  <span>
                     <FormControl className={css.formControl}>
                        <TextField
                           id="standard-search"
                           label="Search"
                           type="search"
                           onChange={handleSearchUpdate}
                           inputProps={{ 'aria-label': 'search' }}
                        />
                     </FormControl>
                  </span>
               </div>
               <Collateral items={visibleCollaterals}></Collateral>
            </div>
            {determineSeeMoreVisibility() && (
               <div className={css.buttonContainer}>
                  <a
                     onClick={showMore}
                     className={[
                        css.sansPro,
                        css.pt30,
                        css.semiBold,
                        css.uppercase,
                        css.button,
                     ].join(' ')}
                  >
                     See More
                  </a>
               </div>
            )}
         </div>
      </Layout>
   )
}

export default PublicationPage

export const pageQuery = graphql`
   query PublicationQuery {
      projects: allStrapiProject(
         filter: { Acronym: { nin: ["SGSS","ESP", "SN", "SM", "NEN", "DC-QNet"] } }
         sort: { order: ASC, fields: Name }
      ) {
         nodes {
            Acronym
            Name
         }
      }
      collaterals: allStrapiCollateral(sort: { order: DESC, fields: Publish }) {
         nodes {
            Title
            Alt
            Blurb
            Publish(formatString: "MMMM D, YYYY")
            tags {
               Name
            }
            projects {
               Acronym
               Name
            }
            File {
               localFile {
                  publicURL
               }
            }
            Thumbnail {
               id
               localFile {
                  publicURL
                  childImageSharp {
                     fluid(maxHeight: 960) {
                        ...GatsbyImageSharpFluid
                     }
                  }
               }
            }
         }
      }
      missingImage: file(ext: { eq: ".jpg" }, name: { eq: "not-found" }) {
         id
         name
         publicURL
         childImageSharp {
            fluid(maxHeight: 960) {
               ...GatsbyImageSharpFluid
            }
         }
      }
      headerImage: file(relativePath: { eq: "headers/newsandmultimedia.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 960) {
               ...GatsbyImageSharpFluid
            }
         }
      }
   }
`
