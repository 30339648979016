import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Layout from '../components/layout'
import sharedStyles from '../styles/global'
import Img from 'gatsby-image'
import MediaTabs from '../components/mediaTabs'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import {
   useMediaQuery,
   Input,
   InputLabel,
   MenuItem,
   FormControl,
   Select,
   Checkbox,
   TextField,
   Typography,
} from '@material-ui/core'
import Modal from '../components/collateralModal'
import _ from 'lodash'
import ImageHeader from '../components/imageHeader'
import { isIE } from '../services/utilityService'
import Masonry from 'react-masonry-css'

const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   wrapper: {
      marginTop: '20px',
   },
   formControl: {
      marginBottom: '20px',
      margin: theme.spacing(1),
      minWidth: '100px',
      maxWidth: '500px', //this is the with expanding when selecting amount of selected projects
      [theme.breakpoints.down('sm')]: {
         minWidth: '100px',
         maxWidth: '150px',
      },
   },
   paperSearch: {
      width: '350px',
      [theme.breakpoints.down('sm')]: {
         minWidth: '150px',
      },
   },
   option: {
      whiteSpace: 'pre',
   },
   iconButton: {
      padding: 3,
   },
   // masonry: {
   //    display: '-ms-grid',
   //    display: 'grid',
   //    gridTemplateColumns: 'repeat( auto-fill, minmax( 200px, 1fr ) )',
   //    gridAutoRows: 'auto',
   //    [theme.breakpoints.down('sm')]: {
   //       gridTemplateColumns: '1fr',
   //       msGridColumns: '1fr',
   //    },
   //    [theme.breakpoints.up('sm')]: {
   //       gridTemplateColumns: '1fr 1fr',
   //       msGridColumns: '1fr 1fr',
   //    },
   //    [theme.breakpoints.up('md')]: {
   //       gridTemplateColumns: '1fr 1fr 1fr',
   //       msGridColumns: '1fr 1fr 1fr',
   //    },
   //    [theme.breakpoints.up('lg')]: {
   //       gridTemplateColumns: '1fr 1fr 1fr 1fr',
   //       msGridColumns: '1fr 1fr 1fr 1fr',
   //    },
   // },
   imageBox: {
      position: 'relative',
      borderRadius: '5px',
      backgroundColor: '#eee',
      overflow: 'hidden',
      marginRight: '10px',
      marginBottom: '10px',
   },
   ieImageHeight: {
      maxHeight: '250px',
   },
   image: {
      height: '100%',
   },
   overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      color: 'transparent',
      display: 'flex',
      alignItems: 'flex-start',
      transition: 'all .1s ease-in',
      '&:hover': {
         color: 'white',
         backgroundColor: 'rgba(62, 99, 211, .70)',
      },
   },
   photoTitle: {
      margin: theme.spacing(2, 1, 0, 1),
      lineHeight: '1.2',
   },
   modalImage: {
      minWidth: '400px',
      [theme.breakpoints.down('sm')]: {
         minWidth: '250px',
      },
   },
   downloadButton: {
      // padding: theme.spacing(1.3, 6),
      padding: theme.spacing(1.3, 1),
      borderRadius: '8px',
      color: 'white',
      backgroundColor: '#1D3787',
      cursor: 'pointer',
      textDecoration: 'none',
   },
   downloadButtonContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
   },
   downloadButtonText: {
      textAlign: 'left',
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(5),
   },
   downloadButtonIcon: {
      paddingRight: theme.spacing(0),
      fontSize: '1.2rem',
   },
   masonryContainer: {
      display: 'flex',
      marginLeft: '-10px',
      width: 'auto',
      gridAutoRows: '1px', // Add this line
      [theme.breakpoints.down('sm')]: {
         gridTemplateColumns: '1fr',
         msGridColumns: '1fr',
      },
      [theme.breakpoints.up('sm')]: {
         gridTemplateColumns: '1fr 1fr',
         msGridColumns: '1fr 1fr',
      },
      [theme.breakpoints.up('md')]: {
         gridTemplateColumns: '1fr 1fr 1fr',
         msGridColumns: '1fr 1fr 1fr',
      },
      [theme.breakpoints.up('lg')]: {
         gridTemplateColumns: '1fr 1fr 1fr 1fr',
         msGridColumns: '1fr 1fr 1fr 1fr',
      },
   },
}))

const ImageGallery = ({ data }) => {
   const theme = useTheme()
   const css = useStyles(theme)

   const [selectedPhoto, setSelectedPhoto] = React.useState(null)
   const [open, setOpen] = React.useState(false)
   const handleClose = () => {
      setOpen(false)
   }

   const handleImageClick = (image, event) => {
      setSelectedPhoto(image)
      setOpen(true)
   }

   const allImages = data.allStrapiImage.edges.map(x => x.node)
   const photos = allImages
      .filter(img => img.File != null)
      .map(function(x) {
         const aspectRatio = x.File.localFile.childImageSharp
            ? x.File.localFile.childImageSharp
            : 1.5
         const width = 2
         const height = width / aspectRatio
         const spans = Math.max(Math.ceil(11 * height), 15) * 10
         return {
            fluid: x.File.localFile.childImageSharp
               ? x.File.localFile.childImageSharp.fluid
               : 1.5,
            src: x.File.localFile.publicURL,
            width: width,
            height: height,
            alt: x.Alt,
            title: x.Title,
            caption: x.Caption,
            spans: spans,
            ratio: aspectRatio,
            file: x.File,
            projects: x.projects,
         }
      })
   const gridGap = []

   //get distinct array of projects
   const allProjects = data.allStrapiProject.nodes
   const [searchCriteria, setSearchCriteria] = React.useState({
      projects: [],
      searchText: '',
   })
   const [visiblePhotos, setVisiblePhotos] = React.useState(photos)
   // useEffect(() => {
   //    shuffleArray(visiblePhotos)
   // }, [visiblePhotos])
   const projectNames = searchCriteria.projects || []

   function handleProjectSelection(event) {
      const acronyms = event.target.value
      searchCriteria.projects = acronyms
      setSearchCriteria(searchCriteria)
      filterImages(searchCriteria)
   }

   function handleSearchUpdate(event) {
      searchCriteria.searchText = event.target.value
      setSearchCriteria(searchCriteria)
      filterImages(searchCriteria)
   }

   function filterImages(criteria) {
      let visible = photos
      if (criteria && criteria.projects && criteria.projects.length > 0) {
         visible = visible.filter(photo => {
            if (photo && photo.projects) {
               const intersection = _.intersection(
                  criteria.projects,
                  photo.projects.map(x => x.Acronym)
               )
               if (intersection.length > 0) return true
            } else {
               return false
            }
         })
      }

      if (criteria.searchText) {
         const searchText = criteria.searchText
         visible = visible.filter(x => {
            if (isSubStringFound(x.title, searchText)) return true
            if (isSubStringFound(x.caption, searchText)) return true
            return false
         })
      }
      setVisiblePhotos(visible)
   }

   function isSubStringFound(text, searchText) {
      if (text) {
         const loweredText = text.toLowerCase()
         if (loweredText.indexOf(searchText.toLowerCase()) > -1) return true
      }
      return false
   }

   let columns = setColumns()
   function setColumns() {
      let columns = 4
      if (useMediaQuery(theme.breakpoints.down('sm'))) {
         columns = 1
      }
      if (useMediaQuery(theme.breakpoints.up('sm'))) {
         columns = 2
      }
      if (useMediaQuery(theme.breakpoints.up('md'))) {
         columns = 3
      }
      if (useMediaQuery(theme.breakpoints.up('lg'))) {
         columns = 4
      }
      return columns
   }
   function calculateMSGridPlacement(idx, columns) {
      let col = (idx % columns) + 1
      const row = Math.floor(idx / columns) + 1
      return {
         row,
         col,
      }
   }

   //-- Assume IE for initial load
   let ieCss = [css.ieImageHeight]
   if (typeof window !== 'undefined') {
      if (isIE() === false) ieCss = []
   }
   function shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
         const j = Math.floor(Math.random() * (i + 1))
         ;[array[i], array[j]] = [array[j], array[i]]
      }
   }

   return (
      <Layout>
         <ImageHeader
            title={`News & Multimedia`}
            image={data.HeaderImage}
         ></ImageHeader>
         <div className={css.content}>
            <MediaTabs selected={2} />

            <div className={css.wrapper}>
               <div className={css.searchBox}>
                  <span>
                     <FormControl className={css.formControl}>
                        <InputLabel htmlFor="select-multiple-checkbox">
                           Projects
                        </InputLabel>
                        <Select
                           multiple
                           value={projectNames}
                           onChange={handleProjectSelection}
                           input={<Input id="select-multiple-checkbox" />}
                           renderValue={selected => selected.join(', ')}
                        >
                           {allProjects.map(proj => (
                              <MenuItem key={proj.Acronym} value={proj.Acronym}>
                                 <Checkbox
                                    checked={
                                       projectNames.indexOf(proj.Acronym) > -1
                                    }
                                 />

                                 <Typography variant="inherit" noWrap>
                                    {proj.Name}
                                 </Typography>
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                  </span>
                  <span>
                     <FormControl className={css.formControl}>
                        <TextField
                           id="standard-search"
                           label="Search"
                           type="search"
                           onChange={handleSearchUpdate}
                           inputProps={{ 'aria-label': 'search' }}
                        />
                     </FormControl>
                  </span>
               </div>
               <div className={css.masonry}>
                  <Masonry
                     breakpointCols={{
                        default: 4,
                        1100: 3,
                        700: 2,
                        500: 1,
                     }}
                     className={css.masonryContainer}
                  >
                     {visiblePhotos.map((photo, index) => {
                        const msGrid = calculateMSGridPlacement(index, columns)
                        return (
                           <div
                              className={[css.imageBox, css.ieCss].join(' ')}
                              style={{
                                 gridRowEnd: `span ${photo.spans}`,
                                 msGridRow: msGrid.row,
                                 msGridColumn: msGrid.col,
                              }}
                              key={`Photo_${index}`}
                              onClick={() => handleImageClick(photo)}
                           >
                              {photo.fluid ? (
                                 <Img
                                    className={css.image}
                                    fluid={photo.fluid}
                                    alt={photo.alt}
                                 />
                              ) : (
                                 <img src={photo.src} alt={photo.alt} />
                              )}
                              <div className={css.overlay}>
                                 <div
                                    className={[
                                       css.photoTitle,
                                       css.pt22,
                                       css.sansProSemiBold,
                                    ].join(' ')}
                                 >
                                    {photo.title}
                                 </div>
                              </div>
                           </div>
                        )
                     })}
                  </Masonry>
               </div>
            </div>
         </div>
         {selectedPhoto && (
            <Modal
               title={selectedPhoto.title}
               tags={selectedPhoto.tags}
               isOpen={open}
               handleClose={handleClose}
            >
               <Img
                  className={css.modalImage}
                  fluid={selectedPhoto.fluid}
                  alt={selectedPhoto.alt}
               />
               <div className={css.downloadButtonContainer}>
                  <a
                     href={selectedPhoto.file.localFile.publicURL}
                     target="_blank"
                     className={css.downloadButton}
                  >
                     <span className={css.downloadButtonText}>Download</span>
                     <VerticalAlignBottomIcon
                        className={css.downloadButtonIcon}
                     />
                  </a>
               </div>
            </Modal>
         )}
      </Layout>
   )
}

export default ImageGallery

export const pageQuery = graphql`
   query GalleryQuery {
      allStrapiProject(
         filter: { Acronym: { nin: ["SGSS","ESP", "SN", "SM", "NEN", "DC-QNet"] } }
         sort: { order: ASC, fields: Name }
      ) {
         nodes {
            Acronym
            Name
         }
      }
      allStrapiImage(
         filter: { Add2Gallery: { eq: true } }
         sort: { order: DESC, fields: createdAt }
      ) {
         edges {
            node {
               id
               Title
               Add2Gallery
               Caption
               Alt
               File {
                  id
                  localFile {
                     publicURL
                     childImageSharp {
                        fluid(maxHeight: 960) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
               projects {
                  Acronym
                  Name
               }
            }
         }
      }
      headerImage: file(relativePath: { eq: "headers/newsandmultimedia.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 960) {
               ...GatsbyImageSharpFluid
            }
         }
      }
   }
`
