import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'
import sharedStyles from '../styles/global'
import { Index } from 'elasticlunr'
import { graphql, Link } from 'gatsby'
import {
   makeStyles,
   useTheme,
   Paper,
   CircularProgress,
} from '@material-ui/core'
import WithLocation from '../components/withLocation'
//import DOMPurify from 'dompurify'
//import { JSDOM } from 'jsdom';
import ReferenceType from '../components/referenceType'
import Markdown from 'react-markdown/with-html'
import Parser, { domToReact, htmlToDOM } from 'html-react-parser';

 //const window = new JSDOM('').window;
 //const safeHTML = DOMPurify(window);

 const useStyles = makeStyles(theme => ({
   ...sharedStyles(theme),
   waitContainer: {
      margin: '10vh 0',
      textAlign: 'center',
   },
   resultContainer: {
      margin: '5vh 0',
      fontFamily: 'Open Sans',
   },
   resultItem: {
      color: '#1D3787', // override materialUI Paper
      padding: theme.spacing(2),
      display: 'flex',
      marginBottom: theme.spacing(1),
   },
   resultType: {
      fontWeight: '600',
      textTransform: 'capitalize',
   },
   spaceRight: {
      marginRight: theme.spacing(1),
   },
   noFlex: {
      flex: 'none',
   },
}))

const getQueryStringParams = url => {
   if (typeof window !== 'undefined') {
      const query = window.location.search
      return extractQueryParam(query)
   } else {
      return extractQueryParam(url)
   }
}

function extractQueryParam(query) {
   if (!query) query = ''
   return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
           .split('&')
           .reduce((params, param) => {
              let [key, value] = param.split('=')
              params[key] = value
                 ? decodeURIComponent(value.replace(/\+/g, ' '))
                 : ''
              return params
           }, {})
      : {}
}



function replaceLinks(text) {
  const options = {
    replace: ({ attribs, children, name }) => {
      if (name === 'a') {
        return <span>{domToReact(children, options)}</span>;
      }
    },
  };

  return Parser(text, options);
}

const createResultDisplays = (results, query, css) => {
   if (!results) {
      return [
         <div className={css.pt30}>
            {`Sorry, there were no results for "${query}".`}
         </div>,
      ]
   }

   const displays = results.map(x => createResultDisplay(x, css))
   return displays
}

const createResultDisplay = (result, css) => {
  // Allow iframes to be rendered in the body, sanitize the rest for potential XSS
   // const attr = {
   //    ADD_TAGS: ['iframe'],
   //    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'src'],
   // }
  // const sanitizedHTML = safeHTML.sanitize(result.body , attr)

//   const htmlString =result.body;
//   const parser = new DOMParser();
//   const html = parser.parseFromString(htmlString, 'text/html');
//   const sanitizedHTML= html.body

const sanitizedHTML= replaceLinks(result.body);
   return (
      <div>
         <div
            className={[css.resultType, css.spaceRight, css.noFlex].join(' ')}
         >
            {result.contentType}:{' '}

         {result.internalLink ? (
            <Link
            
               to={`/${result.link}`}
               className={[css.noFlex, css.spaceRight].join(' ')}

            >
               {result.title}
            </Link>
         ) : (
            <a href={result.link} target="_blank">
               {result.title}
            </a>
         )}
         </div>
         {/* Render HTML in the body as a string. Using DOM Purifier to sanitize any potential dangerous HTML */}
         {/* <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div> */}
         <Markdown source={sanitizedHTML} escapeHtml={false} />
         {result.publish ?(
              <ReferenceType text='reference type:' refType={`${result.refType} ${result.publish}`}></ReferenceType>
         ):(
            <ReferenceType text='reference type:' refType={result.refType}></ReferenceType>
         )
         }
      </div>
   )
}

const createResults = rawResults => {
   // early out if null
   if (!rawResults) {
      return []
   }

   const finalResults = []

   rawResults.forEach(item => {
      let results = []
      switch (item.contentType) {
         case 'fact':
            results.push({
               contentType: 'project',
               title: `${item.projectName} (${item.projectAcronym}) Fact`,
               link: `/projects/${item.projectAcronym}`,
               internalLink: true,
               body: item.Content || item.projectName || item.projectAcronym,
               refType: item.contentType,
            })
            break
         case 'highlight':
            results.push({
               contentType: 'highlight',
               title: item.Title,
               link: item.Link,
               internalLink: false,
               body:
                  item.Blurb && item.Blurb.length > 200
                     ? `${item.Blurb.substring(0, 200)}...`
                     : item.Blurb,
               refType: item.contentType,
            })
            break
         case 'new':
            results.push({
               contentType: 'news',
               title: item.Title,
               link: `news/${item.Slug}`,
               internalLink: true,
               body:
                  item.Content && item.Content.length > 200
                     ? `${item.Content.substring(0, 200)}...`
                     : item.Content,
               refType: item.contentType,
            })
            break
         case 'project':
            if (item.Acronym != 'DC-QNet') {
               results.push({
                  contentType: item.contentType,
                  title: item.Name,
                  link: `../projects/${item.Acronym}`,
                  internalLink: true,
                  body: item.Alt,
                  refType: item.contentType,
               })
               break
            } else {
               results.push({
                  contentType: 'Partnership',
                  title: item.Name,
                  link: `../partnerships/${item.Acronym}`,
                  internalLink: true,
                  body: item.Alt,
                  refType: 'partnership',
               })
               break
            }
         case 'service':
            results.push({
               contentType: 'service',
               title: item.Title,
               link: `/our-services`,
               internalLink: true,
               body:
                  item.Description && item.Description.length > 200
                     ? `${item.Description.substring(0, 200)}...`
                     : item.Description,
               refType: item.contentType,
            })
            break
         case 'tag':
            const tagResults = createTagResults(item)
            results.push(...tagResults)
            break
         case 'paper':
            console.log(item)
            results.push({
               contentType: item.contentType,
               title: item.Title,
               link: item.Url,
               internalLink: false,
               body:
                  item.Summary && item.Summary.length > 200
                     ? `${item.Summary.substring(0, 200)}...`
                     : item.Summary,
               refType: `paper`,
            })
            break
         case 'video':
            results.push({
               contentType: 'video',
               title: item.Title,
               link: item.YoutubeUrl,
               internalLink: false,
               body:
                  item.Description && item.Description.length > 200
                     ? `${item.Description.substring(0, 200)}...`
                     : item.Description,
               refType: `video`,
            })
            break
         case 'page':
            results.push({
               contentType: 'About ESC',
               title: `ESC`,
               link: `about`,
               internalLink: true,
               body:
                  item.impactTabContent && item.impactTabContent.length > 200
                     ? `${item.impactTabContent.substring(0, 200)}...`
                     : item.impactTabContent,
               refType: `page`,
            })
            break
         case 'projectPage':
            if (item.Acronym != 'DC-QNet') {
               results.push({
                  contentType: 'project',
                  title: item.Acronym,
                  link: `projects/${item.Acronym}`,
                  internalLink: true,
                  body:
                     item.projectPageContent &&
                     item.projectPageContent.length > 200
                        ? `${item.projectPageContent.substring(0, 200)}...`
                        : item.projectPageContent,
                  refType: item.contentType,
               })
               break
            } else {
               results.push({
                  contentType: 'partnership',
                  title: item.Acronym,
                  link: `../partnerships/${item.Acronym}`,
                  internalLink: true,
                  body:
                     item.projectPageContent &&
                     item.projectPageContent.length > 200
                        ? `${item.projectPageContent.substring(0, 200)}...`
                        : item.projectPageContent,
                  refType: 'partnershipPage',
               })
               break
            }
         default:
            break
      }
      if (!item.contentType && item.Slug) {
         results.push({
            contentType: 'news',
            title: item.Title,
            link: `news/${item.Slug}`,
            internalLink: true,
            body:
               item.Text1.data.Text1 && item.Text1.data.Text1.length > 200
                  ? `${item.Text1.data.Text1.substring(0, 200)}...`
                  : item.Text1.data.Text1,
            refType: 'news',
         })
      } else if (item.YoutubeUrl) {
         results.push({
            contentType: 'video',
            title: item.Title,
            link: item.YoutubeUrl,
            internalLink: false,
            body:
               item.Description && item.Description.length > 200
                  ? `${item.Description.substring(0, 200)}...`
                  : item.Description,
            refType: `video`,
         })
      } else if(item.Blurb){
         results.push({
            contentType: 'highlight',
            title: item.Title,
            link: item.Link,
            internalLink: false,
            body:
               item.Blurb && item.Blurb.length > 200
                  ? `${item.Blurb.substring(0, 200)}...`
                  : item.Blurb,
            refType: 'highlight',
         })
      }
      finalResults.push(...results)
   })

   return finalResults
}

const createTagResults = tag => {
   const results = []
   if (tag.news) {
      tag.news.forEach(x => {
         results.push({
            contentType: 'new',
            title: x.Title,
            link: `news/${x.Slug}`,
            internalLink: true,
            body: x.FormatedContent
               ? x.FormatedContent.length > 200
                  ? `${x.FormatedContent.substring(0, 200)}...`
                  : x.FormatedContent
               : x.FormatedContent,
            refType: `tag`,
         })
      })
   }
   if (tag.videos) {
      tag.videos.forEach(x => {
         results.push({
            contentType: 'video',
            title: x.Title,
            link: x.YoutubeUrl,
            internalLink: false,
            body:
               x.Description.data.Description.length > 200
                  ? `${x.Description.data.Description.substring(0, 200)}...`
                  : x.Description.data.Description,
            refType: `tag`,
         })
      })
   }
   if (tag.paper) {
      console.log(`tag.paper`, tag.paper)
      results.push({
         contentType: 'paper',
         title: tag.paper.Title,
         link: 'publication',
         internalLink: true,
         body:
            tag.paper.Summary.length > 200
               ? `${tag.paper.Summary.substring(0, 200)}...`
               : tag.paper.Summary,
         refType: `tag`,
      })
   }

   return results
}

const SearchResultsPage = props => {
   // only needed if using a URL query param to pass the query
   const url = props.location.href || ''
   const queryParams = getQueryStringParams(url)

   const theme = useTheme()
   const css = useStyles(theme)

   const [index, setIndex] = useState(
      Index.load(props.data.siteSearchIndex.index)
   )
   const [results, setResults] = useState({
      items: [],
      results: [],
      processed: false,
   })

   // use the following if using gatsby internals to pass the query
   //
   // const [query, setQuery] = useState(props.location.state.query)
   // useEffect(() => {
   //     setResults({
   //         items: [],
   //         processed: false
   //     })
   //     setQuery(props.location.state.query)
   // }, [props.location.state.query])

   // use the following if using a URL query param to send the query (linkable)
   //
   const [query, setQuery] = useState(queryParams.query)

   //get queryNews and put it in state
   const [queryNews, setQueryNews] = useState(props.data.allStrapiNew.nodes)
   const filterNews = (queryNews, query) => {
      const lowerCaseQuery = query.toLowerCase()
      return queryNews.filter(newsItem => {
         return (
            (newsItem.Title &&
               newsItem.Title.toLowerCase().includes(lowerCaseQuery)) ||
            (newsItem.Text1.data.Text1 &&
               newsItem.Text1.data.Text1.toLowerCase().includes(lowerCaseQuery))
         )
      })
   }
   const filterVideos = (videos, query) => {
      const lowerCaseQuery = query.toLowerCase()
      return videos.filter(video => {
         return (
            (video.Title &&
               video.Title.toLowerCase().includes(lowerCaseQuery)) ||
            (video.Description &&
               video.Description.toLowerCase().includes(lowerCaseQuery))
         )
      })
   }

   const [queryVideos, setQueryVideos] = useState(props.data.videos.nodes)
   const [queryHighlights, setQueryHighlights] = useState(props.data.allStrapiHighlight.nodes)
   const filterHighLights = (highlights, query) => {
      const lowerCaseQuery = query.toLowerCase()
      return highlights.filter(highlight => {
         return (
            (highlight.Title &&
               highlight.Title.toLowerCase().includes(lowerCaseQuery)) ||
            (highlight.Blurb &&
               highlight.Blurb.toLowerCase().includes(lowerCaseQuery))
         )
      })
   }

   useEffect(() => {
      setResults({
         items: [],
         results: [],
         processed: false,
      })
      setQuery(queryParams.query)

      // Filter the news items based on the user's query
      const filteredNews = filterNews(queryNews, queryParams.query)
      setQueryNews(filteredNews)

      // Filter the video items based on the user's query
      const filteredVideos = filterVideos(queryVideos, queryParams.query)
      setQueryVideos(filteredVideos)

      const filteredHighlights = filterHighLights(queryHighlights, queryParams.query)
      setQueryHighlights(filteredHighlights)
   }, [
      queryParams.query,
      props.data.allStrapiNew.nodes,
      props.data.videos.nodes,
      props.data.allStrapiHighlight.nodes
   ])

   if (!results.processed) {
      const resultDocs = index
         .search(query, { boolean: 'AND', expand: true })
         .map(({ ref }) => index.documentStore.getDoc(ref))
         .concat(queryNews)
         .concat(queryVideos)
         .concat(queryHighlights)
         .sort((a, b) => {
            return ('' + a.contentType).localeCompare(b.contentType)
         })

      const results = createResults(resultDocs).sort((a, b) => {
         return ('' + a.contentType).localeCompare(b.contentType)
      })
      const display = createResultDisplays(results, query, css)

      setResults({
         results: display,
         processed: true,
      })
   }

   return (
      <Layout>
         <Helmet>
            <meta charSet="utf-8" />
            <title>ESC Search Results</title>
         </Helmet>
         <div className={css.content}>
            {results.processed ? (
               <Paper className={css.resultContainer}>
                  {results.results.map((itemDisplay, idx) => (
                     <div className={css.resultItem} key={idx}>
                        {itemDisplay}
                     </div>
                  ))}
               </Paper>
            ) : (
               <div className={css.waitContainer}>
                  <CircularProgress />
               </div>
            )}
         </div>
      </Layout>
   )
}

export default WithLocation(SearchResultsPage)

export const pageQuery = graphql`
   query {
      allStrapiNew(sort: { fields: Publish, order: DESC }) {
         nodes {
            id
            Title
            Subtitle
            author {
               Name
            }
            Slug
            Publish(formatString: "MMMM D, YYYY")
            Text1 {
               data {
                  Text1
               }
            }
         }
      }
      allStrapiHighlight(sort: { fields: Order }) {
         nodes {
            Alt
            Blurb
            Title
            Link
            Order
            Gradient
            Image {
               localFile {
                  childImageSharp {
                     fluid(maxHeight: 960) {
                        ...GatsbyImageSharpFluid
                     }
                  }
               }
            }
            tags {
               Name
               id
            }
         }
      }
      videos: allStrapiVideo(sort: { order: DESC, fields: Publish }) {
         nodes {
            Title
            tags {
               Name
               id
            }
            Description
            id
            Publish(formatString: "MMMM D, YYYY")
            YoutubeUrl
            Thumbnail {
               localFile {
                  childImageSharp {
                     fluid(maxHeight: 960) {
                        ...GatsbyImageSharpFluid
                     }
                  }
               }
            }
         }
      }
      allStrapiService(
         filter: {
            Title: { ne: "Communications and Navigation Network Services" }
            service: { eq: "Communications Network Services" }
         }
         sort: { fields: [service, Position] }
      ) {
         distinct(field: service)
         nodes {
            service
            Position
            Title
            FormattedText {
               data {
                  FormattedText
               }
            }
            contacts {
               Name
               Title
               Email
               Facility
               Phone
            }
         }
      }
      siteSearchIndex {
         index
      }
   }
`

